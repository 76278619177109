export const COLORS = {
    dark: '#0F0D25',
    darkGray:'#1B1930',
    BgGray:'#fbfbfb',
    headingColor:'#3E3E3E',
    purple:'#9430F2',
    purple2:'#A450FF',
    purple3:'#AC5FFF',
    lightBlue:'#51B2ED',
    grayBlue:'#7669EF',
    gray:'#413F51',
    white: '#ffffff',
    dartkWhite:'#D9D9D9',
    black: '#1C1C1C',
    black2:'#000000',
    buttonBlack:'#292929',
    darkBlue:'#0056B3',
    bgBlue:'#07041B',
    gradient: ['#9430F2', '#7669EF', '#51B2ED'],
};
