import * as React from 'react';
import Carousel from 'better-react-carousel';
import './css/home.css';
import './css/flotingImg.scss';
import main from "../assets/img/home/main.avif";
import mainBG from "../assets/img/home/mainBG.png";
import service1 from "../assets/img/home/service1.png";
import service2 from "../assets/img/home/service2.png";
import service3 from "../assets/img/home/service3.png";
import service4 from "../assets/img/home/service4.png";
import service5 from "../assets/img/home/service5.png";
import service6 from "../assets/img/home/service6.png";
import service7 from "../assets/img/home/service7.png";
import service8 from "../assets/img/home/service8.png";
import service9 from "../assets/img/home/service9.png";
import service10 from "../assets/img/home/service10.png";
import service11 from "../assets/img/home/service11.png";
import service12 from "../assets/img/home/service12.png";
import front1 from "../assets/img/techStack/front1.png";
import front2 from "../assets/img/techStack/front2.png";
import front3 from "../assets/img/techStack/front3.png";
import back1 from "../assets/img/techStack/back1.png";
import back2 from "../assets/img/techStack/back2.png";
import back3 from "../assets/img/techStack/back3.png";
import back4 from "../assets/img/techStack/back4.png";
import mobile2 from "../assets/img/techStack/mobile2.png";
import mobile3 from "../assets/img/techStack/mobile3.png";
import mobile4 from "../assets/img/techStack/mobile4.png";
import db1 from "../assets/img/techStack/db1.png";
import db2 from "../assets/img/techStack/db2.png";
import db3 from "../assets/img/techStack/db3.png";
import cloud1 from "../assets/img/techStack/cloud1.png";
import cloud2 from "../assets/img/techStack/cloud2.png";
import cloud3 from "../assets/img/techStack/cloud3.png";
import cloud4 from "../assets/img/techStack/cloud4.png";
import dev1 from "../assets/img/techStack/dev1.png";
import dev2 from "../assets/img/techStack/dev2.png";
import dev3 from "../assets/img/techStack/dev3.png";
import data1 from "../assets/img/techStack/data1.png";
import data2 from "../assets/img/techStack/data2.png";
import data3 from "../assets/img/techStack/data3.png";
import data4 from "../assets/img/techStack/data4.png";
import data5 from "../assets/img/techStack/data5.png";
import sale1 from "../assets/img/techStack/sale1.png";
import banner_2 from '../assets/img/home/banner_2.png';

import newHome from '../assets/img/home/newHome.png';
import homeExpertistbg from '../assets/img/home/homeExpertistbg.png'
import product from '../assets/img/home/product.png';
import productLog from '../assets/img/home/productLogo.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import clientWork from '../assets/img/home/clientWorlLogo.png';
import clientWorklogo from '../assets/img/home/clientWork.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button, Grid, Container, Typography, Box, Card, Stack, Paper } from "@mui/material";
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { ShoppingCart, ArrowForward, ColorizeRounded } from '@mui/icons-material';
import { COLORS as Color } from "../Common/Constant/Color";
import { useNavigate } from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'react-phone-input-2/lib/style.css';
import './css/career.css';
import ContactForm from './../Common/Component/ContactForm';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import DiscoverCard from '../Common/Component/DiscoverCard';
import ClientCards from '../Common/Component/ClientCards';
import WorkWithClient from '../Common/Component/WorkWithClient';
import { GradiantButton } from '../Common/Constant/CommonStyle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const OfferCard = styled(Card)(({ theme }) => ({
    padding: '20px 10px',
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 10
    },
}));

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});





const Home = () => {

    const themeData = useTheme();
    const isMobile = useMediaQuery(themeData.breakpoints.down('sm'));


    const techStack = [
        {
            url: front1,
            name: 'react',
            width: '20%',
        },
        {
            url: front2,
            name: 'react',
            width: '20%',
        },
        {
            url: front3,
            name: 'react',
            width: '20%',
        },

        {
            url: back1,
            name: 'react',
            width: '20%',
        },
        {
            url: back2,
            name: 'react',
            width: '20%',
        },
        {
            url: back3,
            name: 'react',
            width: '20%',
        },
        {
            url: back4,
            name: 'react',
            width: '20%',
        },

        {
            url: front1,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile2,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile3,
            name: 'react',
            width: '20%',
        },
        {
            url: mobile4,
            name: 'react',
            width: '20%',
        },

        {
            url: db1,
            name: 'react',
            width: '20%',
        },
        {
            url: db2,
            name: 'react',
            width: '20%',
        },
        {
            url: db3,
            name: 'react',
            width: '20%',
        },

        {
            url: cloud1,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud2,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud3,
            name: 'react',
            width: '20%',
        },
        {
            url: cloud4,
            name: 'react',
            width: '20%',
        },

        {
            url: dev1,
            name: 'react',
            width: '20%',
        },
        {
            url: dev2,
            name: 'react',
            width: '20%',
        },
        {
            url: dev3,
            name: 'react',
            width: '20%',
        },

        {
            url: data1,
            name: 'react',
            width: '20%',
        },
        {
            url: data2,
            name: 'react',
            width: '20%',
        },
        {
            url: data3,
            name: 'react',
            width: '20%',
        },
        {
            url: data4,
            name: 'react',
            width: '20%',
        },
        {
            url: data5,
            name: 'react',
            width: '20%',
        },
    ];
    const services = [
        {
            url: service1,
            title: 'IOS Development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },
        {
            url: service2,
            title: 'web development',
            description: 'Our web development solutions focus on creating visually stunning, user-friendly, and functional websites, ensuring an exceptional online experience for visitors through responsive design and seamless navigation.',
        },
        {
            url: service3,
            title: 'mobile development',
            description: 'LYXUX offers advanced iOS app development, utilizing the latest technologies and industry best practices to create captivating mobile experiences for Apple devices, delivering high-quality, engaging apps that drive success.',
        },

        {
            url: service4,
            title: 'digital marketing',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service5,
            title: 'enterprise solutions',
            description: 'LYXUX Enterprise Solutions streamline business operations, enhance collaboration, and drive growth by integrating seamlessly into existing systems, empowering organizations to reach their full potential.',
        },
        {
            url: service6,
            title: 'blockchain development',
            description: 'LYXUX offers comprehensive services for blockchain technology, including development, smart contract implementation, and DApp creation, transforming business operations, enhancing data integrity, and facilitating secure transactions.',
        },
        {
            url: service7,
            title: 'multimedia creations',
            description: 'LYXUX offers comprehensive digital marketing services, optimizing websites for search engines, enhancing social media engagement, and employing data-driven strategies for maximum ROI and continuous growth.',
        },
        {
            url: service8,
            title: 'IoT solutions',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },
        {
            url: service9,
            title: 'salesforce',
            description: 'Lyxux offers expert Salesforce solutions to boost your business. Our certified team specializes in Sales Cloud, Service Cloud, and Non-Profit Cloud services. We provide seamless integration, custom app development, and insightful analytics to help you optimize sales and enhance customer interactions. With our efficient and effective solutions, we help your business grow faster and smarter.',
        },
        {
            url: service10,
            title: 'UI/UX designing',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        }
        ,
        {
            url: service11,
            title: 'Robotics',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },
        ,
        {
            url: service12,
            title: 'AI',
            description: 'LYXUX offers comprehensive services in IoT, focusing on device communication, architecture design, and data analytics to drive innovation, optimize operations, and embrace the digital revolution.',
        },

    ];

    const discoverProduct = [
        {
            description: 'Explore cutting-edge web applications designed and maintained by Lyxux, tailored to meet your business needs. Our products combine innovation with reliability, offering seamless performance and top-tier security. Trust Lyxux to deliver solutions that drive your success in the digital world.',
            img: product,
            logo: productLog
        },
        {
            description: 'Explore cutting-edge web applications designed and maintained by Lyxux, tailored to meet your business needs. Our products combine innovation with reliability, offering seamless performance and top-tier security. Trust Lyxux to deliver solutions that drive your success in the digital world.',
            img: product,
            logo: productLog
        },
        {
            description: 'Explore cutting-edge web applications designed and maintained by Lyxux, tailored to meet your business needs. Our products combine innovation with reliability, offering seamless performance and top-tier security. Trust Lyxux to deliver solutions that drive your success in the digital world.',
            img: product,
            logo: productLog
        }
    ]

    const clientWorks = [
        {
            topic: 'A Web Application Transforming Clubfoot Care for Families',
            description: '"Make Me Smile" is a web application designed to streamline clubfoot treatment for families. The platform connects parents of children with clubfoot to pediatric specialists, enabling them to schedule appointments, access treatment guidance, and find customized orthopedic shoes. It also allows interaction with shoemakers for personalized footwear and facilitates secure payments. The admin panel supports healthcare providers in managing profiles, appointments, and patient care. By simplifying the treatment process, "Make Me Smile" ensures that families have the tools and support they need to provide effective care for children with clubfoot.',
            logo: clientWorklogo,
            img: clientWork
        },
        {
            topic: 'A Web Application Transforming Clubfoot Care for Families',
            description: '"Make Me Smile" is a web application designed to streamline clubfoot treatment for families. The platform connects parents of children with clubfoot to pediatric specialists, enabling them to schedule appointments, access treatment guidance, and find customized orthopedic shoes. It also allows interaction with shoemakers for personalized footwear and facilitates secure payments. The admin panel supports healthcare providers in managing profiles, appointments, and patient care. By simplifying the treatment process, "Make Me Smile" ensures that families have the tools and support they need to provide effective care for children with clubfoot.',
            logo: clientWorklogo,
            img: clientWork
        },
        {
            topic: 'A Web Application Transforming Clubfoot Care for Families',
            description: '"Make Me Smile" is a web application designed to streamline clubfoot treatment for families. The platform connects parents of children with clubfoot to pediatric specialists, enabling them to schedule appointments, access treatment guidance, and find customized orthopedic shoes. It also allows interaction with shoemakers for personalized footwear and facilitates secure payments. The admin panel supports healthcare providers in managing profiles, appointments, and patient care. By simplifying the treatment process, "Make Me Smile" ensures that families have the tools and support they need to provide effective care for children with clubfoot.',
            logo: clientWorklogo,
            img: clientWork
        }
    ]

    const products = [
        { name: 'IMPULZE TRADE', nav: '/' },
        { name: 'BEE IN MIND', nav: '/' },
        { name: 'LYXUX MAGAZINE', nav: '/' },
        { name: 'LYX SPORT', nav: '/' },
        { name: 'LYX MEDIA', nav: '/' },]
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = React.useState(null);
    const [hoverIndex, setHoverIndex] = React.useState(0);
    const [emailError, setEmailError] = React.useState(false);

    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        contactNo: '',
        subject: '',
        requirement: ''
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (e.target.name === 'email') {
            if (!emailPattern.test(e.target.value)) {
                setEmailError("Invalid email address");
            } else {
                setEmailError(false); 
            }
        }
    }

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, contactNo: value });
    };

    const handleMailSend = () => {
        const { name, email, contactNo, requirement } = formData

        const mailtoLink = `mailto:info@lyxux.com?subject=Free%20Quote%20Request&body=Name:%20${encodeURIComponent(
            name
        )}%0AEmail:%20${encodeURIComponent(email)}%0AContact%20Number:%20${encodeURIComponent(
            contactNo
        )}%0ARequirement:%20${encodeURIComponent(requirement)}`

        window.location.href = mailtoLink;

        
    }

    const MainButton = styled(Button)(({ theme }) => ({
        backgroundColor: Color.white,
        color: Color.dark,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const SendButton = styled(Button)(({ theme }) => ({
        color: Color.white,
        backgroundColor: Color.purple2,
        fontSize: 23,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        borderRadius: '60px',
        width: '100%',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const ViewMoreButton = styled(Button)(({ theme }) => ({
        color: Color.dark,
        backgroundColor: Color.white,
        fontSize: 17,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid ${Color.dark}`,
        marginTop: 30,
        padding: '0 10px',
        '&:hover': {
            backgroundColor: Color.dark,
            color: Color.white,
        },
    }));
    const InvisibleViewMoreButton = styled(Button)(({ theme }) => ({
        color: 'transparent',
        backgroundColor: 'transparent',
        fontSize: 17,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        border: `1px solid transparent`,
        marginTop: 30,
        padding: '0 10px'
    }));


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const items = [
        {
            name: "Slide 1",
            description: "This is the first slide.",
            backgroundColor: "#FFABAB",
        },
        {
            name: "Slide 2",
            description: "This is the second slide.",
            backgroundColor: "#B5EAEA",
        },
        {
            name: "Slide 3",
            description: "This is the third slide.",
            backgroundColor: "#FFEBB7",
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % items.length);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? items.length - 1 : prevActiveStep - 1));
    };

    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            backgroundColor: Color.bgBlue
        }}>
            {/* <Box style={{ backgroundImage: `linear-gradient(to right, #9430ff1a, #7669ef1a, #51b2ed1a)`, justifyContent: "space-between" }}>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {products.map((item, index) => (
                        <Button key={index} className="gradient-text" style={{ fontFamily: 'Be Vietnam Pro', fontWeight: 400 }}
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(item.nav)
                            }} children={item.name} />
                    ))}
                </Box>
            </Box> */}
            <div>
                <Box sx={{
                    margin: 0, textAlign: 'center', backgroundImage: `url(${newHome})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    maxWidth: '93%',
                    minHeight: { xs: '70vh', md: '100vh' },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    
                    <Box sx={{
                        width: '100%',
                        ml: { xs: 0, md: 20 }
                    }}>
                        <AnimationOnScroll  initiallyVisible={true} animateIn={"animate__fadeInUp"} offset={0}>
                        <Typography sx={{
                            fontSize: { xs: '30px', md: '60px' },
                            fontWeight: 700,
                            textAlign: { xs: 'center', md: 'left' },
                            lineHeight: { xs: 'auto', md: 1 }
                        }}><GradientTextSpan>Empowering Innovation</GradientTextSpan> <br /><GradientTextSpan>with Trust</GradientTextSpan><br /> <GradientTextSpan>and Security</GradientTextSpan></Typography>


                        {isMobile ? (
                            <Typography sx={{
                                color: Color.white,
                                fontSize: { xs: '15px', md: '20px' },
                                textAlign: { xs: 'center', md: 'left' },
                                mt: { xs: 5, md: 0 },
                                mx: 5

                            }}>
                                Your reliable partner in software engineering. We build solutions with precision, ensuring your business thrives in a secure and trustworthy environment.
                            </Typography>
                        ) : (
                            <Typography sx={{
                                color: Color.white,
                                fontSize: { xs: '15px', md: '20px' },
                                textAlign: { xs: 'center', md: 'left' },
                                mt: { xs: 5, md:7 }

                            }}>
                                Your reliable partner in software engineering. We build <br />solutions with precision, ensuring your business thrives in a <br />secure and trustworthy environment.
                            </Typography>
                        )}


                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: { xs: 'center', md: 'left' },
                            gap: 2,
                            mt: { xs: 5, md: 7 }
                        }}>
                            <GradiantButton


                                onClick={handleClickOpen}
                            >
                                Get A Quote
                            </GradiantButton>

                            <GradiantButton onClick={() => { window.scrollTo(0, 0); navigate('/about') }}>About us</GradiantButton>
                        </Box>
                        </AnimationOnScroll>

                    </Box>

                </Box>

            </div>


            <div class="slider">
                <div class="slide-track">
                    {techStack.map((tech, index) => (
                        <div class="slide" key={index}>
                            <img style={{ width: 50, filter: isHovered ===tech.url ? 'none':'grayscale(90%) brightness(60%)',transition: 'filter 0.3s ease-in-out',cursor:'pointer' }}
                                src={tech.url} alt=""
                                onMouseEnter={() => setIsHovered(tech.url)}
                                onMouseLeave={() => setIsHovered(null)}
                            />
                        </div>
                    ))}
                    {techStack.map((tech, index) => (
                        <div class="slide" key={index}>
                            <img style={{ width: 50, filter: isHovered ===tech.url ? 'none':'grayscale(90%) brightness(60%)',transition: 'filter 0.3s ease-in-out',cursor:'pointer'}}
                                src={tech.url} alt=""
                                onMouseEnter={() => setIsHovered(tech.url)}
                                onMouseLeave={() => setIsHovered(null)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1}>

                <Grid item xs={12} md={6} sx={{ textAlign: { md: 'left', sm: 'center' }, mx: { xs: 5, md: 0 } }}>
                    <Grid sx={{ paddingLeft: { sm: 5, xs: 0 }, mx: { xs: 0, md: 10 } }}>
                        <Typography sx={{ color: Color.white, fontSize: { xs: '30px', md: '60px', fontWeight: 700 } }}>Why <span style={{ color: Color.purple2 }}>LYXUX?</span></Typography>

                        <Box sx={{ color: Color.white, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: { xs: '100%', md: '60%' } }}>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>5</Typography>
                                <Typography>Years of Experience</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>43</Typography>
                                <Typography>Talents in a team</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ color: Color.white, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: { xs: '100%', md: '60%' } }}>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>160K+</Typography>
                                <Typography>Hours spent on making work</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>123</Typography>
                                <Typography>Successfull projects</Typography>
                            </Box>
                        </Box>
                        <hr
                            style={{
                                border: 'none',
                                height: '0.5px',
                                background: Color.purple3,
                            }}
                        />

                        <Typography sx={{ color: Color.white, fontSize: { xs: 13, md: 22 }, textAlign: {xs:'center',md:'justify'}, mt: '20px' }}>
                            We believe that trust and security are the foundations of successful software engineering. Our mission is to deliver cutting-edge solutions that not only meet your business needs but also ensure the highest levels of security and reliability. With a team of experienced engineers, we specialize in creating simple yet powerful software that drives growth and innovation. We pride ourselves on our commitment to excellence, transparency, and client satisfaction.
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            mt: 5
                        }}>
                            <GradiantButton onClick={()=>{window.scrollTo(0, 0); navigate('/contact')}}>Let's Start Your Project</GradiantButton>
                            <Button
                                endIcon={<ArrowForwardIcon sx={{ backgroundColor: Color.headingColor, borderRadius: '100%' }} />}
                                sx={{
                                    color: Color.white,
                                    textTransform: 'capitalize',
                                    fontWeight: 700,
                                    fontSize: { xs: 'auto', md: '20px' }
                                }}
                                onClick={()=>{window.scrollTo(0, 0); navigate('/about')}}
                            >Get To Know Us</Button>
                        </Box>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { md: 'block', sm: 'none', xs: 'none' } }}>
                    {/* <img style={{ width: "85%" }} src={main} alt="" /> */}
                    
                    <img src={banner_2} style={{ width: '100%' }} className="floating-image" />
                </Grid>
            </Grid>
            </AnimationOnScroll>

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1} style={{ marginTop: "20px" }}>

                <Grid item xs={12}>
                    <Box sx={{
                        margin: 0, textAlign: 'center', backgroundImage: `url(${homeExpertistbg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center', maxWidth: '100%',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, fontWeight: 700, color: Color.white }}>Trusted <span style={{ color: Color.purple2 }}>Expertise</span></Typography>
                        <Typography sx={{ fontSize: { xs: '15px', md: '20px' }, fontWeight: 400, color: Color.white, mx: 5, textAlign: { xs: 'center', md: 'center' } }}>At Lyxux, we deliver tailored software solutions with a focus on security and innovation. From seamless integrations to advanced technology implementation, our expert team ensures your business stays ahead. Trust us for reliable and efficient software that drives your success.</Typography>

                        <Box sx={{ width: '90%', margin: '0 auto', mt: 4 }}>

                            <Carousel
                                hideArrow={false}
                                cols={3} rows={1} gap={90} loop>
                                {services.map((service, index) => (
                                    <Carousel.Item xs={12} sm={6} md={6} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Grid key={index} item xs={12} sm={12} md={12}>
                                            <OfferCard
                                                onMouseEnter={() => setHoverIndex(index)}
                                                onMouseLeave={() => setHoverIndex(-1)}

                                                sx={{
                                                    background: 'linear-gradient(90deg, #00B5FF, #BF3DFF)',
                                                    borderRadius: '20px',
                                                    padding: '2px',
                                                    '& > *': {
                                                        background: 'linear-gradient(90deg, #0D1A3A 0%, #1B1931 100%)',
                                                        borderRadius: '18px',
                                                        padding: '16px',
                                                    },
                                                }}
                                            >
                                                <Container>
                                                    <Box sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'left'
                                                    }}><img style={{ height: '70px' }} src={service.url} alt={service.title} /></Box>
                                                    <Typography
                                                        sx={{ fontSize: { xs: '20px', md: '25px' }, fontWeight: 600, color: Color.white, textAlign: 'left', textTransform: 'capitalize' }}
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                    >
                                                        {service.title}
                                                    </Typography>

                                                    <Typography
                                                        sx={{ fontSize: { xs: '11px', md: '15px' }, fontWeight: 400, color: Color.white, textAlign: {xs:'center',md:'justify'} }}
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                    >
                                                        {service.description}
                                                    </Typography>
                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                                                        <Button sx={{ fontSize: '20px', color: Color.white, textTransform: 'capitalize', fontWeight: 700 }}
                                                            endIcon={<ArrowForwardIcon sx={{ borderRadius: '100%', backgroundColor: Color.gray, textTransform: 'capitalize' }} />}
                                                        >
                                                            Let's Get Start
                                                        </Button>
                                                    </Box>
                                                </Container>
                                            </OfferCard>

                                        </Grid>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            </AnimationOnScroll>
            {/* <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                <Container sx={{ my: '20px' }}>

                    <Carousel cols={1} rows={1} gap={50} loop >
                        {discoverProduct.map((product, index) => (
                            <Carousel.Item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid key={index} item xs={12} sm={12} md={10}>
                                    <DiscoverCard img={product.img} description={product.description} logo={product.logo} />
                                </Grid>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Container>
            </AnimationOnScroll> */}

            {/* <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Container sx={{ my: '30px' }}>
                <Carousel cols={1} rows={1} gap={1} loop>
                    {clientWorks.map((data, index) => (
                        <Carousel.Item key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} sm={12} md={10}>
                                <WorkWithClient logo={data.logo} img={data.img} description={data.description} topic={data.topic} />
                            </Grid>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
            </AnimationOnScroll> */}
            
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Box>
                <Typography sx={{ fontSize: { xs: '30px', md: '60px', fontWeight: 700, color: Color.white } }}>Some Of Our <span style={{ color: Color.purple2 }}>Clients</span></Typography>

                <ClientCards />
            </Box>

            </AnimationOnScroll>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{ backgroundColor: 'transperant' }}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{
                    background: 'linear-gradient(90deg, #00B5FF, #BF3DFF)',
                    borderRadius: '20px',

                }}>
                    <DialogContent
                        sx={{

                            background: 'linear-gradient(90deg, #0D1A3A 0%, #1B1931 100%)',
                            borderRadius: '1px',
                            padding: '10px',
                            color: Color.white
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={5}

                        >
                            <Grid item

                                justifyContent="center"
                                alignItems="center"
                                xs={10} style={{ textAlign: 'left' }}>
                                <Typography sx={{ fontSize: { xs: '20px', md: '30px', fontWeight: 700, color: Color.dartkWhite, textAlign: 'center' } }}>Get Your Free Quote Today</Typography>
                                <p style={{ textAlign: 'center' }}>We Are Exited To Provide You With A Customized Quote Tailored To Your Needs. Please Fill Out The Form Below, And Our Team Will Get Back To You Shortly With All The Details.</p>
                                <label style={{ textAlign: 'left', fontSize: 18, color: Color.white }}>Your Name</label>
                                <OutlinedInput size="small" style={textfieldStyle}
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <label style={{ textAlign: 'left', fontSize: 18, color: Color.white }}>Email</label>
                                <OutlinedInput size="small" style={textfieldStyle}
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={!!emailError}
                                    helperText={emailError || ''}
                                />
                                <label style={{ textAlign: 'left', fontSize: 18, color: Color.white }}>Contact Number</label>
                                {/* <OutlinedInput size="small" style={textfieldStyle}
                                    name='contactNo'
                                    value={formData.contactNo}
                                    onChange={handleChange}
                                /> */}
                                <PhoneInput
                                    country={'us'}
                                    style={textfieldStyle}
                                    value={formData.contactNo}
                                    onChange={handlePhoneChange}
                                    inputStyle={{
                                        width: '100%',
                                        height: '40px',
                                        borderRadius: '10px',
                                        paddingLeft: '48px', 
                                        backgroundColor:Color.bgBlue,
                                        color:Color.white,
                                        border: 'double 1px transparent',
                                    }}
                                    buttonStyle={{
                                        borderRadius: '5px 0 0 5px',
                                        border: '1px solid #ccc',
                                    }}

                                />

                                <label style={{ textAlign: 'left', fontSize: 18, color: Color.white }}>Your Requirement</label>
                                <OutlinedInput style={textfieldStyle} multiline
                                    rows={4}
                                    name='requirement'
                                    value={formData.requirement}
                                    onChange={handleChange}
                                />
                                <SendButton onClick={handleMailSend}
                                >Send</SendButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>

            </BootstrapDialog>
        </div >
    )
}

const AboutButtonStyle = {
    marginTop: 20,
    backgroundColor: Color.dark,
    padding: '4px 28px',
    color: Color.white,
    fontSize: 23,
    fontWeight: 'bold',
    textTransform: 'capitalize',
};
const textfieldStyle = {
    border: 'double 1px transparent',
    backgroundColor: Color.bgBlue,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    color:'white',
    width: '100%',
    borderRadius: 10,
    margin: '5px 0 10px 0',
};
export default Home;
