import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { COLORS } from "./Color"

const GradiantButton = styled(Button)({
    
    position: 'relative', 
    borderRadius: '60px', 
    padding: '8px 20px',
    fontWeight:700,
    fontSize:{xs:'15px',md:'20px'},
    color: COLORS.white,
    backgroundColor: COLORS.bgBlue, 
    overflow: 'hidden', 
    zIndex: 1,  
    textTransform: 'capitalize',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '60px', 
      padding: '2px', 
      background: 'linear-gradient(90deg, #BF3DFF 0%, #00B5FF 100%)',  
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',  
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      zIndex: -1,  
    },

    '&:hover::before': {
      background: 'linear-gradient(90deg, #BF3DFF 0%, #00B5FF 100%)',  
    },
  
})

export {
    GradiantButton
}