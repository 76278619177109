import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LOGO from "../../assets/img/lyxuxLogo.png";
import LYXUXNew from '../../assets/img/LYXUXNew.png'
import LyxButtonLOGO from "../../assets/img/headerButtonLogo.png";
import { Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { COLORS as Color } from "../Constant/Color";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Header.scss';
import { GradiantButton } from "../Constant/CommonStyle";
import { useLocation } from "react-router-dom";
const newnewNavItems = [
    { name: 'Home', nav: '/' },
    { name: 'About us', nav: '/about' },
    { name: 'Services', nav: '/services' },
    // { name: 'Portfolio', nav: '/portfolio' },
    // { name: 'Careers', nav: '/career/available' },
    { name: 'Contact us', nav: '/contact' }
]


const ColorButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 400,
    borderRadius: 10,
    padding: '2px 18px',
    backgroundColor: 'transparent',
    border: `1px solid ${Color.purple3}`,
    textTransform: 'capitalize',

    '&:hover': {
        backgroundColor: 'transparent',
        border: `1px solid ${Color.purple2}`
    },
}));
const StyledListItemText = styled(ListItemText)(({ theme, selected }) => ({
    color: selected ? Color.purple : Color.dartkWhite,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 400,
}));


const Header = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get current location
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: Color.bgBlue, color: Color.dartkWhite, height: '100%' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img src={LYXUXNew} style={{ width: '80px' }} alt={'logo'} />
            </Typography>
            <List>
                {newnewNavItems.map((item, index) => (
                    <ListItem key={item.name}>
                        <ListItemButton
                            sx={{ textAlign: 'center', fontWeight: 400 }}
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate(item.nav);
                            }}
                        >
                            <StyledListItemText
                                primary={item.name}
                                selected={location.pathname === item.nav} // Check current path
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar component="nav" style={{ backgroundColor: Color.bgBlue, color: Color.white, boxShadow: 'none' }}>
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon style={{ color: Color.dartkWhite }} />
                    </IconButton>
                    <img src={LYXUXNew} style={{ width: '60px', marginLeft: '10%' }} alt={'logo'} />
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {newnewNavItems.map((item, index) => (
                            <Button 
                                style={{ 
                                    color: location.pathname === item.nav ? Color.purple : Color.white, 
                                    fontFamily: 'Be Vietnam Pro', 
                                    fontWeight: 400, 
                                    textTransform: 'capitalize' 
                                }} 
                                key={item.name}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate(item.nav);
                                }}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                    <GradiantButton
                        onClick={() => {
                            window.scrollTo(0, 0);
                            window.open("https://buy.stripe.com/dR65lqbCp6at2l23cd", "_blank", "noreferrer");
                        }}
                        
                        sx={{ marginRight: { xs: '1%', md: '10%' } }}
                    >
                        Payment Portal
                    </GradiantButton>
                </Toolbar>
            </AppBar>
            <Box component="nav" style={{ backgroundColor: Color.theme }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: Color.theme },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Header;