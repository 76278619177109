import { Box, Button, Stack, Typography } from "@mui/material"
import { COLORS as Color } from '../Constant/Color'
import { GradiantButton } from "../Constant/CommonStyle"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const WorkWithClient = ({ img, logo, description, topic }) => {
    const themeData = useTheme();
    const isMobile = useMediaQuery(themeData.breakpoints.down('sm'));
    return (
        <Box sx={{ px: { xs: 3.5, md: 5 } }}>
            <Typography sx={{ fontSize: { xs: '30px', md: '60px', fontWeight: 700, color: Color.white }, textAlign: { xs: 'center', md: 'left' } }}>Our Works With <span style={{ color: Color.purple2 }}>Clients</span></Typography>
            <Stack direction={{ xs: "column", md: 'row' }} justifyContent={'center'}>
                <Box sx={{ width: { xs: '100%', md: '50%' } }}>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'left' }
                    }}>
                        <img src={img} style={{ width: '80%' }} />
                    </Box>
                    <Typography sx={{ color: Color.darkBlue, fontSize: { xs: '20px', md: '35px' }, fontWeight: 600, textAlign: { xs: 'center', md: 'left' } }}>{topic}</Typography>
                    {isMobile && (
                        <Box sx={{ mt: { xs: 3, md: 0 } }}>
                            <img style={{ width: "100%" }} src={logo} alt="" />
                        </Box>
                    )}
                    <Typography sx={{ fontSize: 15, lineHeight: 2, textAlign: {xs:'center',md:'justify'}, color: Color.white, mt: { xs: 3, md: 0 } }}>{description}</Typography>

                    <Box sx={{ display: 'flex', width: '100%', justifyContent: { xs: 'center', md: 'left' }, mt: 3 }}>
                        <GradiantButton
                            endIcon={<ArrowForwardIcon />}
                        >Check it Out!</GradiantButton>
                    </Box>
                </Box>
                {!isMobile && (
                    <Box sx={{ mt: { xs: 3, md: 0 } }}>
                        <img style={{ width: "100%" }} src={logo} alt="" />
                    </Box>
                )}
            </Stack>
        </Box>
    )
}
export default WorkWithClient