import React from 'react';
import { Button, Grid, Container, Typography, Box } from "@mui/material";
import { COLORS as Color } from "../Constant/Color";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { styled } from '@mui/material/styles';
import brand1 from "../../assets/img/home/ImrookaLogo.png";
import brand2 from "../../assets/img/home/KadawathaHomes.png";
import brand3 from "../../assets/img/home/crystalClear.png";
import brand4 from "../../assets/img/home/Yegabiyo.jpg";
import brand5 from "../../assets/img/home/esbLogo.png";
import brand6 from "../../assets/img/home/ImrookaSpecieLogo.png";



const ClientCards = () => {
    const [isHovered, setIsHovered] = React.useState(null);
    const brands = [
        {
            url: brand1,
            title: 'brand1',
            width: '20%',
        },
        {
            url: brand2,
            title: 'brand2',
            width: '20%',
        },
        {
            url: brand3,
            title: 'crystalClear',
            width: '20%',
        },
        {
            url: brand4,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand5,
            title: 'brand5',
            width: '20%',
        },
        {
            url: brand6,
            title: 'brand6',
            width: '20%',
        },
    ];

    const repeatedBrands = Array(12).fill(brands).flat();
    return (
        <Container style={{
            marginBottom: 10, width: '100%',
        }}>
            <Box>
                

                <div class="slider">
                    <div class="slide-track">
                        {repeatedBrands.map((tech, index) => (
                            <div class="slide" key={index}>
                                <img style={{ width: 80,filter: isHovered ===tech.url ? 'none':'grayscale(90%) brightness(60%)',transition: 'filter 0.3s ease-in-out',cursor:'pointer' }}
                                    src={tech.url} alt=""
                                    onMouseEnter={() => setIsHovered(tech.url)}
                                    onMouseLeave={() => setIsHovered(null)}
                                />
                            </div>
                        ))}

                    </div>
                </div>
            </Box>


        </Container>
    );
};


export default ClientCards;
