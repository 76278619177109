import * as React from 'react';
import { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import Carousel from 'better-react-carousel'
import { Grid, Container, Link, Typography, Box, Stack, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import ContactForm from './../Common/Component/ContactForm';
import { COLORS as Color, COLORS } from "../Common/Constant/Color";
import flag1 from "../assets/img/contact/flag1.png"
import flag2 from "../assets/img/contact/flag2.png"
import London from "../assets/img/contact/london.avif";
import Spain from "../assets/img/contact/Barcelona.avif";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Card from '@mui/material/Card';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Testimonial from '../Common/Component/Testimonial';
import { styled } from '@mui/material/styles';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Rating } from '@mui/material';
import GlobalMap from '../Common/Component/GlobalMap';
import contactBg from '../assets/img/contact/contactBg.png'
import BothSideCard from '../Common/Component/BothSideCard';
// import CalendlyWidget from './CalendlyWidget';
const VisionMissonCard = styled(Card)(({ theme }) => ({
    textAlign: 'left',
    boxShadow: 'none',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: 10

}));

const servicesData = [
    'IOS Development',
    'Web Development',
    'Blockchain Development',
    'IoT Solution',
    'Digital Marketing',
    'Multimedia Creations',
    'UI/UX Designing',
    'Robotics',
    'AI',
    'Enterprise Solution',
    'Salesforce',
    'Mobile Development'

]
const Contacts = () => {

    return (
        <div style={{
            fontFamily: 'Helvetica',
            marginTop: '80px',
            marginBottom: '20px'
        }}>



            <Container>
            <Typography sx={{ color: Color.white, fontSize: { xs: '30px', md: '60px'}, fontWeight: 700  }}>Our <span style={{ color: Color.purple2 }}>Global Presence</span></Typography>
            </Container>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item sm={6} xs={12}>
                    <Typography sx={{ textTransform: 'capitalize', margin: '10px 0', color: Color.white ,mx:{xs:5,md:0},fontSize:{xs:'14px',md:'20px'}, textAlign:{xs:'center',md:'center'}}}>We proudly serve clients in multiple countries. Check out the map below to see the regions we operate in. If you are in one of these areas, we are just a call or click away.</Typography>
                </Grid>
                <GlobalMap />
            </Grid>


            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

            <Box sx={{ marginTop: 1,mx:{xs:0,md:7} }}>


                <Typography sx={{ color: Color.white, fontSize: { xs: '18px', md: '50px' }, fontWeight: 700, textAlign: { xs: 'center', md: 'left' },my:10 }}>Let's talk about how to transform your <span style={{ color: Color.purple2 }}><br />Business</span></Typography>

                <Typography sx={{
                    fontSize:{xs:'15px',md:'20px'},
                    textAlign:'left',
                    mb:5,
                    color: Color.white,
                    mx:{xs:5,md:0}

                }}>Fill Out the Form Below and We'll Be in Touch Soon.
                </Typography>
            </Box>
            </AnimationOnScroll>
            <Box sx={{
                margin: 0, textAlign: 'center', backgroundImage: `url(${contactBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center', maxWidth: '100%',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: Color.white
            }}>
                
                <Stack direction={{ xs: 'çolumn', md: 'row' }} spacing={4} sx={{ width: '50%', }} alignItems={'center'}>
                    <Box sx={{
                        width: { xs: '100%', md: '70%' },
                        backgroundColor: Color.black2,
                        opacity: 0.8,
                        textAlign: 'left',
                        p: { xs: 1, md: 5 },
                        borderRadius: '20px'
                    }}>
                        <ContactForm />
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '100%', md: '30%' },
                            textAlign: 'left'
                        }}
                    >
                        {/* <BothSideCard front={Spain} back={"sad"}/> */}

                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: {xs:'20px',md:'25px'},
                            color: Color.white
                        }}>Barcelona office</Typography>
                        <Typography sx={{
                            
                            fontSize: {xs:'15px',md:'20px'},
                            color: Color.white
                        }}>LYXUX GLOBAL<br />
                            SOCIEDAD LIMITADA<br />
                            Barcelona, Spain.<br />
                            <b>Telephone:</b>(+34) 603 84 55 46<br />
                            <b>Email:</b><a href='info@lyxuxglobal.com' style={{textDecoration:'none',color:COLORS.white}}>info@lyxuxglobal.com</a></Typography>

                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: {xs:'20px',md:'25px'},
                            color: Color.white,
                            mt: '20px'
                        }}>London office</Typography>
                        <Typography sx={{
                            
                            fontSize: {xs:'15px',md:'20px'},
                            color: Color.white
                        }}>LYXUX GLOBAL<br />
                            London UK,<br />
                            <b>Telephone:</b>(+44) 79 156 119 88<br />
                            <b>Email:</b><a href="mailto:info@lyxux.com" style={{textDecoration:'none',color:COLORS.white}}>info@lyxux.com</a></Typography>
                    </Box>
                </Stack>
            </Box>


        </div>
    )
}

export default Contacts;
