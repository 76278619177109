import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import flag1 from "../../assets/img/contact/flag1.png";
import flag2 from "../../assets/img/contact/flag2.png";

import RoomIcon from "@mui/icons-material/Room";

const GlobalMap = () => {
  useEffect(() => {
    var root = am5.Root.new("chartdiv");

    // Create the map chart
    var chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoEquirectangular(),
      })
    );

    // Create series for background fill
    var backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    backgroundSeries.mapPolygons.template.setAll({
      fill: am5.color(0xE6E9EB),
      fillOpacity: 0,
      strokeOpacity: 0,
    });

    // Create gradient fill
    var gradient = am5.LinearGradient.new(root, {
      stops: [
        { color: am5.color("#A450FF"), offset: 0 }, 
        { color: am5.color("#A450FF"), offset: 0.5 }, 
        { color: am5.color("#7669EF"), offset: 1 },
      ],
      rotation: 0
    });

    // Create main polygon series for countries
    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      fillGradient:gradient,
      tooltipText: "{name}",
      templateField: "polygonSettings",
    });

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color("#BF3DFF"),
    });

    // Create line series for trajectory lines
    var lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: am5.color(0xE6E9EB),
      strokeOpacity: 0.3,
    });

    // Create point series for markers
    var pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

    pointSeries.bullets.push(function () {
      var container = am5.Container.new(root, {
        tooltipText: "{title}",
        cursorOverStyle: "pointer",
      });

      var icon = container.children.push(
        am5.Graphics.new(root, {
          fill: am5.color(0xFF0000),
          svgPath:
            "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z",
          scale: 1,
          centerX: am5.p50,
          centerY: am5.p50,
        })
      );

      icon.animate({
        key: "scale",
        from: 1,
        to: 1.1,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });
      icon.animate({
        key: "opacity",
        from: 1,
        to: 0.5,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic),
        loops: Infinity,
      });

      return am5.Bullet.new(root, {
        sprite: container,
      });
    });

    var cities = [
      {
        title: "United Kingdom",
        latitude: 55.3781,
        longitude: -3.436,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Spain",
        latitude: 40.4168,
        longitude: -3.7038,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Finland",
        latitude: 61.9241,
        longitude: 25.7482,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Singapore",
        latitude: 1.29027,
        longitude: 103.851959,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Sri Lanka",
        latitude: 6.927079,
        longitude: 79.861244,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Malaysia",
        latitude: 4.2105,
        longitude: 101.9758,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Denmark",
        latitude: 56.2639,
        longitude: 9.5018,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "India",
        latitude: 28.7041,
        longitude: 77.1025,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Pakistan",
        latitude: 33.6995,
        longitude: 73.0363,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
      {
        title: "Bangladesh",
        latitude: 23.8041,
        longitude: 90.4152,
        url: "http://www.amcharts.com",
        color: "#007D88",
      },
    ];

    for (var i = 0; i < cities.length; i++) {
      var city = cities[i];
      addCity(city.longitude, city.latitude, city.title, city.url);
    }

    function addCity(longitude, latitude, title, url) {
      pointSeries.data.push({
        url: url,
        geometry: { type: "Point", coordinates: [longitude, latitude] },
        title: title,
      });
    }

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => root.dispose();
  }, []);

  const flags = [
    {
      url: flag1,
      country: "flag",
    },
    {
      url: flag2,
      country: "flag",
    },
  ];

  return <div id="chartdiv" style={{ width: "100%", height: "300px" }} />;
};

export default GlobalMap;
