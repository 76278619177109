import './timeLine.css';

const timeLineData = [
    {
        date: '2018',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    },
    {
        date: '2018',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    },
    {
        date: '2018',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    },
    {
        date: '2018',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
    }
]
const Timeline = () => {
    return (
        <div class="container-fluid py-5">

            <div class="row">
                <div class="col-lg-12">

                    <div class="horizontal-timeline">

                        <ul class="list-inline items">
                            {timeLineData.map((data, index) => (
                                <li class="list-inline-item items-list">
                                    <div class="px-4">
                                        <div class="event-date badge bg-info">{data.date}</div>
                                    
                                        <p class="text-muted" style={{fontSize:'15px'}}>{data.description}</p>

                                    </div>
                                </li>
                            ))}
                        </ul>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Timeline