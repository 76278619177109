import { Box, Button, Card, CardMedia, Container, Grid, Icon, Stack, styled, Typography } from "@mui/material";
import WorkIcon from '@mui/icons-material/Work';
import PlaceIcon from '@mui/icons-material/Place';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import HomeIcon from '@mui/icons-material/Home';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { FONTS } from "../Common/Constant/Font";
import bgIcon2 from '../assets/img/careers/bgIcon2.png';
import bgIcon1 from '../assets/img/careers/bgIcon1.png';
import { COLORS } from "../Common/Constant/Color";
import { useNavigate } from "react-router-dom";
import careerPageBg from '../assets/img/careers/careerPageBg.png'
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../utils/Api";


const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(0deg, #7669EF 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const JobCard = styled(Card)(({ theme }) => ({
    textAlign: 'left',
    backgroundColor: 'transparent',
    color: COLORS.white,
    boxShadow: 'none',

    '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 10
    },
    background: 'linear-gradient(90deg, #00B5FF, #BF3DFF)',
    borderRadius: '20px',
    padding: '2px',

    '& > *': {
        background: 'linear-gradient(90deg, #0D1A3A 0%, #1B1931 100%)',
        borderRadius: '18px',
        padding: '16px',
        minHeight: 200,
        minWidth:300
    }

}));

const dataset = [
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    },
    {
        topic: 'Creative And UI Designer',
        main: 'United Kingdom',
        companyLocation: 'Colombo',
        workingTime: 'Full-Time',
        workingStyle: 'Hybrid',
    }
]

const CareersPage = () => {
    const navigate = useNavigate();
    const [availableVacancy,setAvailableVacancy] = useState([])

    useEffect(()=>{
        fetchData()
    },[])

    const fetchData = async ()=> {
        try{
            const responce = await axios.get(API + 'jobs')

            

            if(responce.data.code === 200){
                setAvailableVacancy(responce.data.data)
            }
        }catch(err){
            console.error(err)
        }
    }

    const handleNavigate = (id) =>{
        navigate(`/View&Apply/${id}`)
    }
    return (
        <Box sx={{ mt: '80px'}}>
            <Container>
                <Typography sx={{ color: COLORS.white, fontSize: { xs: '30px', md: '60px', fontWeight: 700 },mb:{xs:3,md:0} }}>Join <span style={{ color: COLORS.purple2 }}>Our Team</span></Typography>
                <Typography sx={{
                    fontSize: { xs: '14px', md: '18px' },
                    fontFamily: FONTS.Helvetica,
                    textAlign:{xs:'center',md:'center'},
                    mb:{xs:4,md:0}
                }}>
                    At LYXUX, We Believe In Empowering Our Employees To Grow And Succeed.
                    <GradientTextSpan> Our Team Is Our Greatest Asset</GradientTextSpan>, And We Are Always Looking For
                    <GradientTextSpan> Talented, Triven Individuals</GradientTextSpan> To Join Us.
                </Typography>

                <Box sx={{
                    margin: 0, textAlign: 'center', backgroundImage: `url(${careerPageBg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center', maxWidth: '100%',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <AnimationOnScroll animateOnce={true} initiallyVisible={true} animateIn="animate__fadeInUp">
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="center" mb={2}>
                        <Grid container spacing={{xs:0.5,md:2}} justifyContent="center" >
                            {availableVacancy.map((data, index) => (
                                <Grid item xs={12} md={4} key={index}>
                                    <JobCard>
                                        <Box>
                                            <Typography sx={{
                                                fontSize: '30px',
                                                fontWeight: 700,
                                                color: COLORS.purple2
                                            }}>{data.Title}</Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Box>
                                                        <PlaceIcon />
                                                    </Box>
                                                    <Box>
                                                        <Typography>{data.Location}</Typography>
                                                    </Box>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1}>
                                                    <WatchLaterIcon />
                                                    <Typography>{data.time}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Box sx={{
                                                width:'100%',
                                                display:'flex',
                                                justifyContent:'right',
                                                
                                            }}>
                                                <Button
                                                sx={{
                                                    color:COLORS.white,
                                                    textTransform: 'capitalize',
                                                    fontWeight:700
                                                }}
                                                endIcon= {<ArrowForwardIcon sx={{
                                                    borderRadius:'100%',
                                                    backgroundColor:COLORS.grayBlue,
                                                    color:COLORS.white
                                                }}/>}
                                                onClick={()=>handleNavigate(data.Job_id)}
                                                >
                                                    View And Apply
                                                </Button>
                                            </Box>
                                        </Box>
                                    </JobCard>
                                </Grid>

                            ))}
                        </Grid>
                    </Stack>
                    </AnimationOnScroll>
                </Box>
                <Grid container spacing={0} alignItems="center">

                    <Grid item xs={12} md={10} sx={{ mt: { xs: 'auto', md: '100px' } }}>

                    </Grid>
                </Grid>

            </Container>

        </Box>
    );
}

export default CareersPage;
