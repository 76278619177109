import { Button, Container, Grid, Link, Typography } from "@mui/material";
import * as React from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LOGO from "../../assets/img/WhiteLYXUX.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { COLORS as Color } from "../Constant/Color";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { GradiantButton } from "../Constant/CommonStyle";

const FooterGradiantButton = styled(Button)({

    position: 'relative',
    borderRadius: '10px',
    fontSize:{xs:'12px', md:'20px'},
    padding: '8px 32px',
    maxWidth:'200px',
    margin:'auto',
    color: Color.white,
    backgroundColor:Color.dark,
    overflow: 'hidden',
    zIndex: 1,
    textTransform: 'capitalize',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '10px',
        padding: '2px',
        background: 'linear-gradient(90deg, #BF3DFF 0%, #00B5FF 100%)',
        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude',
        zIndex: -1,
    },

    '&:hover::before': {
        background: 'linear-gradient(90deg, #BF3DFF 0%, #00B5FF 100%)',
    },

})

const Footer = () => {
    const navigate = useNavigate();
    const ContactButton = styled(Button)(({ theme }) => ({
        lineHeight: '23px',
        border: '1px solid #fff',
        borderRadius: '8px',
        color: '#fff',
        fontWeight: 800,
        marginBottom: '20px',
        padding: '10px 24px',
        fontSize: '18px',
        textAlign: 'center',
        transition: 'all .3s',

        backgroundColor: Color.dark,

        '&:hover': {
            backgroundColor: Color.white,
            color: Color.dark,
        },
    }));

    return (
        <footer style={{ backgroundColor: '#0F0D25', color: 'white', fontFamily: 'Helvetica', }}>
            <Container style={{ paddingTop: 40 }}>
                <Grid style={{ maxWidth: '100%' }}>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                    >
                        <Grid container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            item xs={12} sm={3}
                            sx={{
                                textAlign: {
                                    xs: 'center',
                                    sm: 'left',
                                }, width: '100%'
                            }}>
                            <img src={LOGO} style={{ width: '125px' }} alt={'logo'} />

                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" style={{ width: '80%', marginTop: '20px' }}>
                                <Link onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'purple'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'} style={{ border: '1px solid white', padding: '5px', borderRadius: '100px', margin: "0 2px", transition: 'background-color .3s' }} href="https://www.linkedin.com/company/lyxux/"
                                    underline="none" color="inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    <LinkedInIcon sx={{ color: Color.white, fontSize: 20, margin: '0 3px' }} />
                                </Link>

                                <Link onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'purple'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'} style={{ border: '1px solid white', padding: '5px', borderRadius: '100px', margin: "0 2px", transition: 'background-color .3s' }} href="https://x.com/lyxuxtech"
                                    underline="none" color="inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    <TwitterIcon sx={{ color: Color.white, fontSize: 20, margin: '0 3px' }} />
                                </Link>

                                <Link onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'purple'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'} style={{ border: '1px solid white', padding: '5px', borderRadius: '100px', margin: "0 2px", transition: 'background-color .3s' }} href="https://www.facebook.com/share/7kVC56CH7bEpfUrh/?mibextid=LQQJ4d"
                                    underline="none" color="inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    <FacebookIcon sx={{ color: Color.white, fontSize: 20, margin: '0 3px' }} />
                                </Link>
                                <Link onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'purple'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'} style={{ border: '1px solid white', padding: '5px', borderRadius: '100px', margin: "0 2px", transition: 'background-color .3s' }} href="https://instagram.com/lyxux_ltd?igsh=MXVwdXljYzBhMng1NQ%3D%3D&utm_source=qr" underline="none"
                                    color="inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    <InstagramIcon sx={{ color: Color.white, fontSize: 20, margin: '0 3px' }} />
                                </Link>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} sm={3} sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            }
                        }}>
                            <Grid container
                                direction="row" style={{ padding: '10px 0' }}>
                                <Grid item xs={12} sm={2}>
                                    <PhoneIcon sx={{ color: Color.white, fontSize: 30 }} />
                                </Grid>
                                <Grid item xs={12} sm={10}
                                    style={{ color: Color.regular, fontSize: 20 }}>
                                    <Link href="https://wa.me/+447915611988" underline="none" color="inherit" style={{ color: Color.white, fontSize: 18, fontFamily: 'Helvetica', }}
                                        onClick={() => {
                                            window.location.href = `https://wa.me/+447915611988 `;
                                        }}><Typography>+44 79 156 119 88 </Typography></Link>
                                    <Link href="https://wa.me/+34603845546" underline="none" color="inherit" style={{ color: Color.white, fontSize: 18, fontFamily: 'Helvetica', }}
                                        onClick={() => {
                                            window.location.href = `https://wa.me/+34603845546`;
                                        }}>+34 603 84 55 46</Link>
                                </Grid>
                            </Grid>

                            <Grid container
                                direction="row" style={{ padding: '10px 0' }}>
                                <Grid item xs={12} sm={2}>
                                    <EmailIcon sx={{ color: Color.white, fontSize: 30 }} />
                                </Grid>
                                <Grid item xs={12} sm={10}
                                    style={{
                                        color: Color.white,
                                        fontSize: 18,
                                        fontFamily: 'Helvetica',
                                    }} onClick={() => {
                                        window.location.href = `mailto:info@lyxux.com?`;
                                    }}><u>info@lyxux.com</u>
                                </Grid>
                            </Grid>
                            <Grid container
                                direction="row" style={{ padding: '10px 0' }}>
                                <Grid item xs={12} sm={2}>
                                    <LocationOnIcon sx={{ color: Color.white, fontSize: 30 }} />
                                </Grid>
                                <Grid item xs={12} sm={10}
                                    style={{ color: Color.white, fontSize: 18, fontFamily: 'Helvetica', }}>
                                        <Typography>
                                        LYXUX GLOBAL, 
                                        </Typography>
                                    <Typography>
                                    SOCIEDAD LIMITADA
                                    </Typography>
                                    <Typography>
                                    Barcelona, Spain.
                                    </Typography>
                                    <br></br>
                                    <Typography>LYXUX LTD
                                    </Typography>
                                    <Typography>
                                    London, UK.</Typography>
                                    </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} sm={2} sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            }
                        }}>

                            <Container
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: Color.white,
                                    fontSize: 14,
                                    lineHeight: '20px',
                                    fontFamily: 'Helvetica',
                                }}>
                                <h2 style={{ color: Color.white, fontSize: 18, }}>Industries</h2>
                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    Finance
                                </Link>
                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    Food
                                </Link>
                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    Hotel
                                </Link>
                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    CRM
                                </Link>
                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    Real State
                                </Link>

                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    Entertainment
                                </Link>

                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    Sport
                                </Link>



                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            }
                        }}>

                            <Container
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: Color.white,
                                    fontSize: 14,
                                    lineHeight: '20px',
                                    fontFamily: 'Helvetica',
                                }}>
                                <h2 style={{ color: Color.white, fontSize: 18, }}>Our Services
                                </h2>
                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Web Development
                                </Link>
                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    IOS Development
                                </Link>
                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Mobile Development
                                </Link>
                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Digital Marketing
                                </Link>
                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Enterprise Solution
                                </Link>

                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out',
                                        fontSize: '12.4px'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Blockchain Development
                                </Link>

                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out',
                                        fontSize: '12.4px'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Multimedia Creation
                                </Link>

                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out',
                                        fontSize: '12.4px'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    IOS Solution
                                </Link>



                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            }
                        }}>

                            <Container
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: Color.white,
                                    fontSize: 14,
                                    lineHeight: '20px',
                                    fontFamily: 'Helvetica',
                                }}>
                                <h2 style={{ color: Color.white, fontSize: 18, }}>Quick Links</h2>
                                <Link
                                    href="#"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/');
                                    }}
                                >
                                    Home
                                </Link>
                                <Link
                                    href="#/about"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/about');
                                    }}
                                >
                                    About Us
                                </Link>
                                <Link
                                    href="#/services"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/services');
                                    }}
                                >
                                    Services
                                </Link>
                                {/* <Link
                                    href="#/portfolio"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/portfolio');
                                    }}
                                >
                                    Portfolio
                                </Link> */}
                                {/* <Link
                                    href="#/career/available"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/career/available');
                                    }}
                                >
                                    Careers
                                </Link> */}

                                <Link
                                    href="#/contact"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                        navigate('/contact');
                                    }}
                                >
                                    Contact Us
                                </Link>

                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{
                            textAlign: {
                                xs: 'center',
                                sm: 'left',
                            }
                        }}>
                            <Container
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: Color.white,
                                    fontSize: 14,
                                    lineHeight: '20px',
                                    fontFamily: 'Helvetica',
                                }}>
                                {/* <FooterGradiantButton onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('/contact')
                                }} >Contact Us</FooterGradiantButton> */}

                                <Link
                                    href="mailto:info@lyxux.com?"
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        marginTop:'10px',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    info@lyxux.com
                                </Link>
                                {/* <h2 style={{
                                    fontSize: '18px',
                                    lineHeight: '20px',
                                    fontWeight: 800,
                                    marginBottom: '20px',
                                    opacity: 1
                                }}>Office</h2>
                                <p
                                    underline="none"
                                    color="inherit"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginBottom: "12px",
                                        opacity: 0.5,
                                        transition: 'opacity 0.3s ease-in-out',
                                        margin: 0
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.5'}

                                >
                                    Spain, London
                                </p> */}

                            </Container>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <div style={{ width: '100%', paddingTop:'10px'}}>
                <div style={{ width: '80%', height: '1px', backgroundColor: '#2C005B', margin: 'auto' }}></div>
            </div>
            <Container style={{ backgroundColor: '#0F0D25', minWidth: '100%', margin: '10px 0 0 0', paddingTop: '5px', paddingBottom: '20px' }}>
                <Typography style={{ color: Color.white, width: '100%' }}>Copyright © 2024 LYXUX LTD All rights reserved</Typography>
            </Container>
        </footer>
    )
}

export default Footer;
