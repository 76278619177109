import * as React from 'react';
import Carousel from 'better-react-carousel';
import { Grid, Container, Box, Typography, Divider, useTheme, Button, Stack } from "@mui/material";
import blueIcon from "../assets/img/about us/blueIcon.png";
import ClientCards from "../Common/Component/ClientCards";
import './css/flotingImg.scss';

import Vector1 from "../assets/img/about us/Vector1.png";
import Vector2 from "../assets/img/about us/Vector2.png";
import Vector3 from "../assets/img/about us/Vector3.png";
import Vector4 from "../assets/img/about us/Vector4.png";
import Vector5 from "../assets/img/about us/Vector5.png";
import Vector6 from "../assets/img/about us/Vector6.png";
import Vector7 from "../assets/img/about us/Vector7.png";
import Vector8 from "../assets/img/about us/Vector8.png";
import Vector9 from "../assets/img/about us/Vector9.png";
import Vector10 from "../assets/img/about us/Vector10.png";
import Vector11 from "../assets/img/about us/Vector11.png";
import Vector12 from "../assets/img/about us/Vector12.png";
import HoverVector1 from "../assets/img/about us/HoverVector1.png";
import HoverVector2 from "../assets/img/about us/HoverVector2.png";
import HoverVector3 from "../assets/img/about us/HoverVector3.png";
import HoverVector4 from "../assets/img/about us/HoverVector4.png";
import HoverVector5 from "../assets/img/about us/HoverVector5.png";
import HoverVector6 from "../assets/img/about us/HoverVector6.png";
import HoverVector7 from "../assets/img/about us/HoverVector7.png";
import HoverVector8 from "../assets/img/about us/HoverVector8.png";
import HoverVector9 from "../assets/img/about us/HoverVector9.png";
import HoverVector10 from "../assets/img/about us/HoverVector10.png";
import HoverVector11 from "../assets/img/about us/HoverVector11.png";
import HoverVector12 from "../assets/img/about us/HoverVector12.png";
// import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { COLORS as Color } from "../Common/Constant/Color";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import banner_2 from '../assets/img/home/banner_2.png';
import service1 from "../assets/img/home/service1.png";
import service2 from "../assets/img/home/service2.png";
import service3 from "../assets/img/home/service3.png";
import service4 from "../assets/img/home/service4.png";
import service5 from "../assets/img/home/service5.png";
import service6 from "../assets/img/home/service6.png";
import service7 from "../assets/img/home/service7.png";
import service8 from "../assets/img/home/service8.png";
import service9 from "../assets/img/home/service9.png";
import service10 from "../assets/img/home/service10.png";
import front1 from "../assets/img/techStack/front1.png";
import front2 from "../assets/img/techStack/front2.png";
import front3 from "../assets/img/techStack/front3.png";
import back1 from "../assets/img/techStack/back1.png";
import back2 from "../assets/img/techStack/back2.png";
import back3 from "../assets/img/techStack/back3.png";
import back4 from "../assets/img/techStack/back4.png";
import mobile2 from "../assets/img/techStack/mobile2.png";
import mobile3 from "../assets/img/techStack/mobile3.png";
import mobile4 from "../assets/img/techStack/mobile4.png";
import db1 from "../assets/img/techStack/db1.png";
import db2 from "../assets/img/techStack/db2.png";
import db3 from "../assets/img/techStack/db3.png";
import cloud1 from "../assets/img/techStack/cloud1.png";
import cloud2 from "../assets/img/techStack/cloud2.png";
import cloud3 from "../assets/img/techStack/cloud3.png";
import cloud4 from "../assets/img/techStack/cloud4.png";
import dev1 from "../assets/img/techStack/dev1.png";
import dev2 from "../assets/img/techStack/dev2.png";
import dev3 from "../assets/img/techStack/dev3.png";
import data1 from "../assets/img/techStack/data1.png";
import data2 from "../assets/img/techStack/data2.png";
import data3 from "../assets/img/techStack/data3.png";
import data4 from "../assets/img/techStack/data4.png";
import data5 from "../assets/img/techStack/data5.png";
import sale1 from "../assets/img/techStack/sale1.png";
import history_icon from '../assets/img/service/history_icon.png';
import Integrity from '../assets/img/about us/Integrity.png'
import Innovation from '../assets/img/about us/Innovation.png'
import Excellence from '../assets/img/about us/Excellence.png'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GlobalMap from '../Common/Component/GlobalMap';

import team from '../assets/img/about us/team.png'
import clientImg from '../assets/img/about us/clientImg.png'
import ClientComment from '../Common/Component/ClientComment';
import { GradiantButton } from '../Common/Constant/CommonStyle';





import {
    Action,
    Event,
    Subtitle,
    Title,
} from '@reactuiutils/horizontal-timeline';
import Timeline from '../Common/Component/TimeLine';




const VisionMissonCard = styled(Card)(({ theme }) => ({
    textAlign: 'justify',
    backgroundColor: 'transparent',
    color: Color.white,
    boxShadow: 'none',

    '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 10
    },
    background: 'linear-gradient(90deg, #00B5FF, #BF3DFF)',
    borderRadius: '20px',
    padding: '2px',

    '& > *': {
        background: 'linear-gradient(90deg, #0D1A3A 0%, #1B1931 100%)',
        borderRadius: '18px',
        padding: '16px',
        minHeight: 300,

    }

}));

const techCard = styled(Card)(({ theme }) => ({
    textAlign: 'left',
    backgroundColor: 'transparent',
    color: Color.white,
    boxShadow: 'none',

    '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 10
    },
    background: 'linear-gradient(90deg, #00B5FF, #BF3DFF)',
    borderRadius: '20px',
    padding: '2px',

    '& > *': {
        background: 'linear-gradient(90deg, #0D1A3A 0%, #1B1931 100%)',
        borderRadius: '18px',
        padding: '16px',
        minHeight: 300,
    }

}));

const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(90deg, #7669EF 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});
const About = () => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = React.useState(null);
    const [hoverIndex, setHoverIndex] = React.useState(0);


    const timelineData = [
        { title: 'Discovery', description: 'Our team analyzes your requirements and expectations, considers your specific needs, and formulates an initial plan for your review.', side: 'left' },
        { title: 'Design', description: 'From a streamlined architecture to an intuitive user interface, we develop, assess, and refine every design aspect before seeking your approval.', side: 'right' },
        { title: 'Specification', description: 'We conduct thorough due diligence, confirm features with you, agree on timelines, perform feasibility analysis, and finalize the design blueprint.', side: 'left' },
        { title: 'Development', description: 'Our developers, graphic designers, and project managers collaborate to create efficient, high-quality software tailored to your specifications.', side: 'right' },
        { title: 'Quality Assurance', description: 'Embracing test-driven development, we perform rigorous functional and security testing to ensure the software meets the highest standards.', side: 'left' },
        { title: 'Deployment', description: 'Upon receiving your approval, we deploy the software to your desired environment and transfer all custom solution rights to you.', side: 'right' },
        { title: 'Support & Maintenance', description: 'While we design our software for easy maintenance by your team, we also offer ongoing support and maintenance services for more complex or large-scale projects.', side: 'left' },
    ];

    const teamData = [
        {
            name: 'Ishan',
            position: 'owner',
            image: team
        },
        {
            name: 'Ishan',
            position: 'owner',
            image: team
        },
        {
            name: 'Ishan',
            position: 'owner',
            image: team
        },
        {
            name: 'Ishan',
            position: 'owner',
            image: team
        },

    ]

    const clientComment = [
        {
            name: 'Mathew Neville, CEO, The Kindness of Strangers',
            description: 'We are delighted with the platform and specifically in Appdrawns ability to continue to evolve and respond to the needs of our customers as we have developed our proposition and customer understanding.',
            image: clientImg
        },
        {
            name: 'Mathew Neville, CEO, The Kindness of Strangers',
            description: 'We are delighted with the platform and specifically in Appdrawns ability to continue to evolve and respond to the needs of our customers as we have developed our proposition and customer understanding.',
            image: clientImg
        }
    ]

    const techData = [
        {
            topic: 'Frontend Development',
            techs: [front1, front2, front3]
        },
        {
            topic: 'Backend Development',
            techs: [back1, back2, back3, back4]
        },
        {
            topic: 'Mobile Development',
            techs: [mobile2, mobile3, mobile4]
        },
        {
            topic: 'Database Management',
            techs: [db1, db2, db3]
        },
        {
            topic: 'cloud platform',
            techs: [cloud1, cloud2, cloud3, cloud4]
        },
        {
            topic: 'devops tools',
            techs: [dev1, dev2, dev3]
        },
        {
            topic: 'data analytics & AI',
            techs: [data1, data2, data3]
        },
        {
            topic: 'salesforce',
            techs: [data5]
        }
    ]


    const OurValue = [
        {
            title: 'Integrity',
            description: 'We prioritize transparency and honesty in our work, ensuring that we build trust with our clients. By holding ourselves accountable, we consistently deliver on our promises.',
            img: Integrity
        },
        {
            title: 'Innovation',
            description: 'We prioritize transparency and honesty in our work, ensuring that we build trust with our clients. By holding ourselves accountable, we consistently deliver on our promises.',
            img: Innovation
        },
        {
            title: 'Excellence',
            description: 'We prioritize transparency and honesty in our work, ensuring that we build trust with our clients. By holding ourselves accountable, we consistently deliver on our promises.',
            img: Excellence
        }

    ]
    const HowItGoes = [
        {
            title: 'Discovery',
            description: 'Our team analyzes your requirements and expectations, considers your specific needs, and formulates an initial plan for your review.',
        },
        {
            title: 'Design',
            description: 'From a streamlined architecture to an intuitive user interface, we develop, assess, and refine every design aspect before seeking your approval.',
        },
        {
            title: 'Specification',
            description: 'We conduct thorough due diligence, confirm features with you, agree on timelines, perform feasibility analysis, and finalize the design blueprint.',
        },
        {
            title: 'Development',
            description: 'Our developers, graphic designers, and project managers collaborate to create efficient, high-quality software tailored to your specifications.',
        },
        {
            title: 'Quality Assurance',
            description: 'Embracing test-driven development, we perform rigorous functional and security testing to ensure the software meets the highest standards.',
        },
        {
            title: 'Deployment',
            description: 'Upon receiving your approval, we deploy the software to your desired environment and transfer all custom solution rights to you.',
        },
        {
            title: 'Support & Maintenance',
            description: 'While we design our software for easy maintenance by your team, we also offer ongoing support and maintenance services for more complex or large-scale projects.',
        },

    ];
    const theme = useTheme();




    return (
        <div style={{
            fontSize: ' calc(10px + 2vmin)',
            fontFamily: 'Helvetica',
            marginTop: '80px',
            backgroundColor: Color.bgBlue,
            color: Color.white
        }}>



            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" spacing={1}
                sx={{
                    mx: { xs: 0, md: 10 },
                    width: '93%'
                }}
            >
                
                <Grid item xs={12} md={6} sx={{ textAlign: { md: 'left', sm: 'center' }, mx: { xs: 5, md: 0 } }}>
                <AnimationOnScroll  initiallyVisible={true} animateIn={"animate__fadeInLeft"} offset={0}>
                    <Grid sx={{ paddingLeft: { sm: 5, xs: 0 } }}>
                        <Typography sx={{ color: Color.white, fontSize: { xs: '30px', md: '60px', fontWeight: 700 } }}>Why <span style={{ color: Color.purple2 }}>LYXUX?</span></Typography>

                        <Box sx={{ color: Color.white, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: { xs: '100%', md: '60%' } }}>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>8+</Typography>
                                <Typography>Years of Experience</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>100+</Typography>
                                <Typography>Talents in a team</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ color: Color.white, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: { xs: '100%', md: '60%' } }}>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>160K+</Typography>
                                <Typography>Hours spent on making work</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: { xs: '40px', md: '65px' }, fontWeight: 700 }}>120+</Typography>
                                <Typography>Successfull projects</Typography>
                            </Box>
                        </Box>

                        <hr
                            style={{
                                border: 'none',
                                height: '0.5px',
                                background: Color.purple3,
                            }}
                        />

                        <Typography sx={{ color: Color.white, fontSize: { xs: 13, md: 22 }, textAlign: { xs: 'center', md: 'justify' }, mt: '20px' }}>
                            We believe that trust and security are the foundations of successful software engineering. Our mission is to deliver cutting-edge solutions that not only meet your business needs but also ensure the highest levels of security and reliability. With a team of experienced engineers, we specialize in creating simple yet powerful software that drives growth and innovation. We pride ourselves on our commitment to excellence, transparency, and client satisfaction.
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            mt: { xs: 3, md: 0 }
                        }}>
                            <GradiantButton onClick={()=>{window.scrollTo(0, 0);navigate('/contact')}}>Let's Start Your Project</GradiantButton>

                        </Box>

                    </Grid>
                    </AnimationOnScroll>
                </Grid>
                
                <Grid item xs={12} md={6} sx={{ textAlign: 'right', display: { md: 'block', sm: 'none', xs: 'none' } }}>
                <AnimationOnScroll  initiallyVisible={true} animateIn={"animate__fadeInRight"} offset={0}>
                    <img style={{ width: "100%" }} src={banner_2} alt="" className="floating-image"/>
                    </AnimationOnScroll>
                </Grid>
            </Grid>

            {/* How We Work */}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Container sx={{ marginTop: 10 }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={2} style={{ marginTop: "20px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 4%' }}>
                            <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, fontWeight: 700 }}>Our <span style={{ color: Color.purple2 }}>Values</span></Typography>
                        </div>
                    </Grid>
                    {OurValue.map((data, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4} sx={{ textAlign: 'left' }}>
                            <Stack direction={'row'} spacing={1}>
                                <Box>
                                    <img src={data.img} style={{ width: '40px', height: '50px' }} />
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        fontSize: '22px',
                                        lineHeight: '30px',
                                        fontWeight: 800
                                    }}>{data.title}</Typography>
                                    <Typography sx={{
                                        fontSize: { xs: '15px', sm: '18px' },
                                        marginTop: '5px',
                                        lineHeight: '28px',
                                        fontWeight: 400,
                                        textAlign: 'justify'
                                    }}>{data.description}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            </AnimationOnScroll>

            {/*start vision & mission*/}
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp"
                style={{ marginTop: '100px', }}>
                <Container>
                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={10}

                        style={{
                            background: 'transparent',

                        }}>
                        <Grid item xs={10} sm={6} justifyContent="center"
                            alignItems="left">
                            <VisionMissonCard>
                                <Container sx={{
                                    backgroundColor: 'transparent',
                                    height: '100%',

                                }}>
                                    <Typography sx={{ fontSize: { xs: '25px', md: '48px' }, color: Color.purple2, fontWeight: 700, textAlign: 'center' }}>Our Vision</Typography>

                                    <Typography sx={{ fontSize: { xs: 15, md: 18 }, lineHeight: 1.5, textTransform: 'capitalize' ,textAlign:'center'}}>At LYXUX, our vision is to be the leading provider of software worldwide, setting the standard for <b>innovation</b>, <b>quality</b>, and <b>customer satisfaction</b>. We aim to empower our customers by delivering solutions that enhance their lives and drive their success.</Typography>
                                </Container>
                            </VisionMissonCard>
                        </Grid>
                        <Grid item xs={10} sm={6} justifyContent="center"
                            alignItems="center" >
                            <VisionMissonCard style={{ height: '100%' }}>
                                <Container>
                                    <Typography sx={{ fontSize: { xs: '25px', md: '48px' }, color: Color.purple2, fontWeight: 700, textAlign: 'center' }}>Our Mision</Typography>

                                    <Typography sx={{ fontSize: { xs: 15, md: 18 }, lineHeight: 1.5, textTransform: 'capitalize',textAlign:'center' }}>Our mission is to revolutionize the software industry by <b>offering cutting-edge software services</b> that exceed expectations.</Typography>
                                </Container>
                            </VisionMissonCard>
                        </Grid>
                    </Grid>
                </Container>
            </AnimationOnScroll>

            {/* <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Container sx={{ marginTop: 10 }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={2} style={{ marginTop: "20px" }}>

                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center', padding: '0 4%' }}>
                            <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, fontWeight: 700 }}>Our <span style={{ color: Color.purple2 }}>History</span></Typography>
                            <Timeline/>
                        </div>
                    </Grid>
                    


                </Grid>
            </Container>
            </AnimationOnScroll> */}

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp"
                style={{ marginTop: '100px', }}>
                <Container>
                    <Typography sx={{
                        fontSize: { xs: '30px', md: '60px' },
                        fontWeight: 700,
                        mt: 5
                    }}>Our <span style={{ color: Color.purple2 }}>Global Presence</span></Typography>
                    <Typography sx={{
                        fontSize: { xs: '13px', md: '18px' },
                        fontWeight: 400,
                        mb: 10
                    }}>We proudly serve clients in multiple countries. Check out the map below to see the regions we operate in. If you are in one of these areas, we are just a call or click away.</Typography>
                    <GlobalMap />
                </Container>

            </AnimationOnScroll>
            {/* <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp"
                style={{ marginTop: '100px', }}>
                <Container sx={{
                    width: '100%',
                    textAlign: 'center',
                    mb: 4,
                    mt: { md: '100px' }
                }}>
                    <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, textAlign: 'center', fontWeight: 700 }}><span style={{ color: Color.purple2 }}>Tech </span>We Use</Typography>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="center" mb={2}>
                        <Grid container
                            rowSpacing={{ xs: 3, md: 2 }}
                            columnSpacing={{ xs: 0, md: 2 }} justifyContent="center">
                            {techData.map((data, index) => (
                                <Grid item xs={12} md={4} key={index}>
                                    <Box
                                        sx={{
                                            background: 'linear-gradient(90deg, #00B5FF, #BF3DFF)',
                                            borderRadius: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: '0',
                                            height: '101%'
                                        }}
                                    >

                                        <Box
                                            sx={{
                                                background: 'linear-gradient(90deg, #0D1A3A 0%, #1B1931 100%)',
                                                borderRadius: '20px',
                                                padding: '20px',
                                                minHeight: '150px',
                                                width: '99%',
                                                boxSizing: 'border-box',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                
                                            }}
                                        >
                                            <Typography sx={{ textTransform: 'capitalize' }}>{data.topic}</Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '20px',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                {data.techs.map((tech, i) => (
                                                    <Grid item key={i}>
                                                        <img
                                                            src={tech}
                                                            alt={`tech-${i}`}
                                                            style={{
                                                                width: '53px',
                                                                height: 'auto',
                                                                filter: isHovered === tech ? 'none' : 'grayscale(90%) brightness(60%)',
                                                                transition: 'filter 0.3s ease-in-out',
                                                                cursor:'pointer'
                                                            }}
                                                            onMouseEnter={() => setIsHovered(tech)}
                                                            onMouseLeave={() => setIsHovered(null)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            ))}
                        </Grid>
                    </Stack>
                </Container>
            </AnimationOnScroll> */}

            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Grid>
                <Grid style={{ paddingBottom: '20px', marginTop: '100px' }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid item>
                            <Typography sx={{ fontSize: { xs: '30px', md: '60px', fontWeight: 700, color: Color.white } }}>Some Of Our <span style={{ color: Color.purple2 }}>Clients</span></Typography>
                        </Grid>
                    </Grid>

                    <ClientCards />
                    {/* </AnimationOnScroll> */}
                </Grid>
            </Grid>
            </AnimationOnScroll>

            {/* <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Container>
                <Box  >
                    <Typography sx={{ fontSize: { xs: '30px', md: '60px', fontWeight: 700, color: Color.white } }}>Meet <span style={{ color: Color.purple2 }}>Our Team</span></Typography>
                    <Box>
                        <img src={team} />
                    </Box>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="center" mb={2}>
                        <Grid container rowSpacing={{ xs: 3, md: 2 }}
                            columnSpacing={{ xs: 0, md: 2 }} justifyContent="center">
                            {teamData.map((data, index) => (
                                <Grid item xs={6} md={3} key={index}>
                                    <img src={data.image} />
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Box>
            </Container>
            </AnimationOnScroll> */}
            {/* <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <Container>
                <Typography sx={{ fontSize: { xs: '30px', md: '60px', fontWeight: 700, color: Color.white }, textAlign: { xs: 'center', md: 'left' } }}>See <span style={{ color: Color.purple2 }}>What Our Clients Say </span><br />About Us</Typography>

                <Carousel cols={1} rows={1} gap={20} loop>
                    {clientComment.map((data, index) => (
                        <Carousel.Item key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} sm={12} md={10}>
                                <ClientComment image={data.image} name={data.name} description={data.description} />
                            </Grid>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
            </AnimationOnScroll> */}

        </div >
    )
}
export default About;
