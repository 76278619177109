import { useEffect, useState } from "react";
import { Box, Button, Stack, styled, TextField, Typography } from "@mui/material"
import { FONTS } from "../Common/Constant/Font";
import { COLORS } from "../Common/Constant/Color";
import { Topic } from "@mui/icons-material";
import { type } from "@testing-library/user-event/dist/type";
import PlaceIcon from '@mui/icons-material/Place';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import axios from "axios";
import { API } from "../utils/Api";
import { useParams } from 'react-router-dom';
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.css';  
import Alertify from 'alertifyjs';



const GradientTextSpan = styled('span')({
    background: '-webkit-linear-gradient(90deg, #7669EF 0%, #7669EF 25%, #51B2ED 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const applyData = [
    {
        Topic: 'UI/UX Designer',
        descreaption: 'Are you a visionary in both aesthetics and user experience? We are on the hunt for a multi-talented Creative + UI Designer  to elevate our digital presence!',
        points: [
            'Proven experience as a Designer with a strong portfolio showcasing your work.',
            'Proficiency in Adobe XD, Adobe Photoshop, and Adobe Illustrator',
            'Solid understanding of design principles, UI design, and graphic design',
            '3 years experience in creating user-friendly and visually appealing designs'
            
        ],
        responsibility: [
            'Develop and execute design concepts for web, mobile, and digital platforms.',
            'Develop and execute design concepts for web, mobile, and digital platforms.',
            'Develop and execute design concepts for web, mobile, and digital platforms.',
            'Develop and execute design concepts for web, mobile, and digital platforms.'
        ]
    }
];


const ViewAndApplyPage = () => {

    const [fileName, setFileName] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [position, setPosition] = useState(null);
    const [message,setMessage] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [vacancyData, setVacancyData] = useState({
        title:'',
        location:'',
        time:'',
        responsibility:[],
        requirements:[],
        description:'',
    })
    const [errorMsg,setErrorMsg] = useState('Fill all before submit');



    const { id } = useParams();

    useEffect(() => {
        dataFetch();
    },[])

    const dataFetch = async() => {
       try{
        const response = await axios.get(API + 'jobs/' + id)
        
        if (response.data.code === 200) {
            const updatedVacancies = response.data.job.map((data) => ({
                ...data, 
                title: data.Title 
            }));
        
            setVacancyData(updatedVacancies); 
        }

        
        
       }catch(err){
        console.error(err)
       }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file);

      }

      const handleEmailChange = (e) => {
        setEmail(e.target.value)

        const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (e.target.name === 'email') {
            if (!emailPattern.test(e.target.value)) {
                setEmailError("Invalid email address");
            } else {
                setEmailError(false); 
            }
        }
    }



    const handleAddApplication = async () => {
        const applicationFormData = new FormData();
        applicationFormData.append('name', name);
        applicationFormData.append('email', email);
        applicationFormData.append('category', position);
        applicationFormData.append('job_id', 1);
        applicationFormData.append('file', fileName); 

        if(!name || !email || !position ){
            setErrorMsg('name is required')
        }
        if(!fileName){
            setErrorMsg('CV is required')
        }
    
        try {
            const response = await axios.post(API + 'add_applicant', applicationFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            

            if(response.status === 201){
                setName('')
                setEmail('')
                setMessage('')
                setPosition('')
                setFileName(null)
                Alertify.alert('Success','Application send')
                
            }else{
                Alertify.alert('Error','Fail')
            }

        } catch (error) {
            console.error('Error adding application:', error.response.data); 
            Alertify.alert('Error',errorMsg)
        }
    };
    
    
    
    return(
        <Box sx={{mt:15,mb:5, mx:5,color:COLORS.white}}>
            <Stack direction={{xs:"column", md:'row'}} spacing={2} sx={{width:'100%'}}>
                <Box sx={{width:{xs:'100%',md:'50%'},
                px:{xs:1,md:5},
                background:'linear-gradient(213.15deg, rgba(128, 128, 128, 0.26) 0%, rgba(26, 26, 26, 0.26) 100%)',
                borderRadius:'20px'
            }}
                
                >
                    <Box sx={{width:'100%', textAlign:{xs:'center',md:'left'}}}>
                        <Typography sx={{
                            fontSize:{xs:'30px', md:'50px'},
                            fontWeight:700,
                            fontFamily:FONTS.Helvetica
                        }}><GradientTextSpan>{vacancyData.title}UI/UX desing</GradientTextSpan></Typography>

                        <Stack direction={'row'} spacing={2}>
                            <Stack direction={"row"} spacing={1}>
                                <Box>
                                    <PlaceIcon />
                                </Box>
                                <Box>
                                    <Typography>Colombo</Typography>
                                </Box>
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <WatchLaterIcon />
                                <Typography>Full Time</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box sx={{width:'100%', textAlign:'left'}}>
                        <Typography sx={{
                            fontSize:{xs:'15px', md:'20px'},
                            mt:5,
                            fontWeight:400,
                            color:COLORS.white,
                            fontFamily:FONTS.Helvetica
                        }}>What We Looking For</Typography>
                        <Box>
                            {applyData.map((data,index)=>(
                                <Box>
                                    <Typography sx={{fontSize:'15px'}}>{data.descreaption}</Typography>
                                    {data.points.map((points,pointIndex)=>(
                                        <ul>
                                            <li style={{fontSize:'15px'}}>{points}</li>
                                        </ul>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        
                    </Box>

                    <Box sx={{width:'100%', textAlign:'left',mt:'40px'}}>
                        <Typography sx={{
                            fontSize:{xs:'15px', md:'20px'},
                            fontWeight:400,
                            color:COLORS.white,
                            fontFamily:FONTS.Helvetica
                        }}>Responsibilities</Typography>
                        <Box>
                            {applyData.map((data,index)=>(
                                <Box>
  
                                    {data.responsibility.map((points,pointIndex)=>(
                                        <ul>
                                            <li style={{fontSize:'15px'}}>{points}</li>
                                        </ul>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        
                    </Box>
                </Box>
                <Box sx={{width:{xs:'100%',md:'50%'},
                    px:{xs:1,md:5},
                    background:'linear-gradient(213.15deg, rgba(128, 128, 128, 0.26) 0%, rgba(26, 26, 26, 0.26) 100%)',
                    borderRadius:'20px'
                    }}>
                    <Box sx={{
                        width:'100%'
                    }}>
                        <Typography sx={{
                            fontSize:{xs:'20px',md:'30px'},
                            fontWeight:700,
                            my:'15px',
                            textAlign:'left'
                        }}>Apply Here</Typography>
                    <TextField id="outlined-basic" label="Your Name" variant="outlined" 
                    onChange={(e) => setName(e.target.value)}
                    sx={{
                                    width: '100%',
                                    height: '46px',
                                    borderRadius: '5px',
                                    mb: '40px',
                                    backgroundColor: COLORS.bgBlue,
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: COLORS.headingColor, 
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white', 
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', 
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white', 
                                    },
                                }}/>
                    <TextField id="outlined-basic" label="Your Email" variant="outlined" onChange={handleEmailChange} 
                    name="email"
                     error={!!emailError}
                    helperText={emailError || ''}
                    sx={{
                                    width: '100%',
                                    height: '46px',
                                    borderRadius: '5px',
                                    mb: '40px',
                                    backgroundColor: COLORS.bgBlue,
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: COLORS.headingColor, 
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white', 
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', 
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white', 
                                    },
                                }}/>
                    <TextField
                                id="outlined-basic"
                                label="Position"
                                variant="outlined"
                                onChange={(e) =>setPosition(e.target.value)}
                                sx={{
                                    width: '100%',
                                    height: '46px',
                                    borderRadius: '5px',
                                    mb: '20px',
                                    backgroundColor: COLORS.bgBlue,
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': {
                                            borderColor: COLORS.headingColor, 
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white', 
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', 
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white', 
                                    },
                                }}
                            />
                        <TextField
                            id="outlined-multiline-static"
                            label="Enter Your Message Here"
                            multiline
                            rows={4}
                            onChange={(e) =>setMessage(e.target.value)}
                            sx={{
                                width: '100%',
                                borderRadius: '5px',
                                mb: '20px',
                                backgroundColor: COLORS.bgBlue,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: COLORS.headingColor,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white', 
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white', 
                                },
                            }}
                        />
                        {fileName !== null ? (
                                                    <Typography sx={{
                                                        fontFamily:'Inter',
                                                        fontWeight:300,
                                                        fontSize:'15px',
                                                        color:COLORS.white,
                                                        textAlign:'left'
                                                    }}>{fileName.name}</Typography>
                        ):(
                            <Typography sx={{
                                fontFamily:'Inter',
                                fontWeight:300,
                                fontSize:'15px',
                                color:COLORS.white,
                                textAlign:'left'
                            }}>Attach your CV here: Accepted file formats are (DOC, DOCX, PDF, PPT, PPTX)</Typography>
                        )}

                        <Box sx={{
                           width:'100%',
                           display:'flex',
                           justifyContent:'left',
                           alineItem:'center'
                        }}>
                            <input
                                accept=".doc,.docx,.pdf,.ppt,.pptx"
                                style={{ display: 'none' }}
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                             />
                            <label htmlFor="file-upload">
                                <Button  component="span" sx={{
                                    width:'103px',
                                    height:'28px',
                                    border:'0.5px solid #000000',
                                    borderRadius:'0.5px',
                                    backgroundColor:COLORS.white,
                                    color:COLORS.buttonBlack,
                                    fontSize:'10px',
                                    transform:'none',
                                    '&:hover': {
                                        backgroundColor: COLORS.white,
                                        transform: 'scale(1.005)',
                                    },
                                }}>
                                    Choose File
                                </Button>
                            </label>
                        {/* {fileName ? (
                            <Typography>{fileName}</Typography>
                        ):(
                            <Typography>No file Chosen</Typography>
                        )} */}

                        </Box>
                        
                        <Box sx={{width:'100%'}}>
                            <Button sx={{
                                width:'100%',
                                height:'51px',
                                borderRadius:'60px',
                                backgroundColor:COLORS.purple2,
                                mt:'20px',
                                color:COLORS.white,
                                transition: 'background 0.3s ease, transform 0.3s ease',

                                '&:hover': {
                                    backgroundColor: COLORS.black,
                                    transform: 'scale(1.005)',
                                },
                            }} 
                              onClick={handleAddApplication}
                              >Send Now</Button>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
};
export default ViewAndApplyPage;
