import { COLORS as Color } from "../Constant/Color";
import { Box, Button, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GradiantButton } from "../Constant/CommonStyle";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const DiscoverCard = ({ img, description, logo }) => {
    const themeData = useTheme();
    const isMobile = useMediaQuery(themeData.breakpoints.down('sm'));
    return (
        <Box sx={{ px: { xs: 1, md: 10 } }}>
            {isMobile && (
                <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, fontWeight: 700, color: Color.white, textAlign: { xs: 'center', md: 'left' } }}>Discover <span style={{ color: Color.purple2 }}>LYXUX Products</span></Typography>
            )}
            <Stack direction={{ xs: "column-reverse", md: "row" }}>
                <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                    {!isMobile && (
                        <Typography sx={{ fontSize: { xs: '30px', md: '60px' }, fontWeight: 700, color: Color.white, textAlign: { xs: 'center', md: 'left' } }}>Discover <span style={{ color: Color.purple2 }}>LYXUX Products</span></Typography>
                    )}
                    <Typography sx={{ fontSize: { xs: '15px', md: '20px' }, fontWeight: 400, color: Color.white, textAlign: {xs:'center',md:'justify'}, mt: { xs: 4, md: 0 } }}>{description}</Typography>
                    <Box sx={{width:'100%', paddingTop:'3rem'}}>
                        <GradiantButton
                            endIcon={<ArrowForwardIcon />}
                        >Check it Out!</GradiantButton>
                    </Box>
                </Box>
                {isMobile ? (
                    <Box sx={{ width: { xs: '100%', md: '50%' }, display: 'flex', justifyContent: 'center', flexDirection: "column" }}>
                        <img src={logo} style={{ scale: isMobile ? '70%' : '100%' }} />
                        <img src={img} style={{ scale: isMobile ? '70%' : '100%' }} />

                    </Box>
                ) : (
                    <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                        <img src={logo} style={{ scale: isMobile ? '70%' : '100%' }} />
                        <img src={img} style={{ scale: isMobile ? '70%' : '100%' }} />

                    </Box>
                )}
            </Stack>
            <Box sx={{
                width: '80%',
                display: 'flex',
                justifyContent: { xs: 'center', md: 'right' },
                mt: 3
            }}>
            </Box>
        </Box>
    )
}

export default DiscoverCard;