import React from 'react';
import { Button, Grid, Container, TextField, Box } from "@mui/material";
import { COLORS as Color } from "../Constant/Color";
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const SendButton = styled(Button)(({ theme }) => ({
    color: Color.white,
    backgroundColor: Color.purple3,
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    border: `1px solid ${Color.dark}`,
    borderRadius:30,
    width: '100%',
    '&:hover': {
        backgroundColor: Color.dark,
        color: Color.white,
    },
}));
const ContactForm = () => {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [contact, setContact] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [requirement, setRequirement] = React.useState("");
    const [emailError, setEmailError] = React.useState('');
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        contactNo: '',
        subject: '',
        requirement: ''
    })

    const handleMailSend = () => {
        const { name, email, contactNo, subject, requirement } = formData

        const mailtoLink = `mailto:info@lyxux.com?subject=Free%20Quote%20Request&body=Name:%20${encodeURIComponent(
            name
        )}%0AEmail:%20${encodeURIComponent(email)}%0AContact%20Number:%20${encodeURIComponent(
            contactNo
        )}%0ASubject:%20${encodeURIComponent(
            subject
        )}%0ARequirement:%20${encodeURIComponent(requirement)}`

        window.location.href = mailtoLink;

        setFormData({
            name: '',
            email: '',
            contactNo: '',
            subject: '',
            requirement: ''
        })
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

       
        const emailPattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(value)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError(''); 
        }
    }

    const handlePhoneChange = (value) => {
        setContact(value)
    };

    return (
        <Container maxWidth="sm">
            <Box component="form" sx={{ mt: 4 }}>
                <Grid sx={{ textAlign: 'left' }} container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: '15px', color: Color.white }}><b>Your Name</b></label>
                        <TextField
                            onChange={(e) => setName(e.target.value)}
                            size="small"
                            fullWidth

                            placeholder="Ex. Jone Alex"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '46px',
                                borderRadius: '5px',
                                mb: '20px',
                                backgroundColor: Color.bgBlue,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: Color.bgBlue,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white', 
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: '15px', color: Color.white }}><b>Email</b></label>
                        <TextField
                            onChange={handleEmailChange}
                            size="small"
                            fullWidth
                            placeholder="Ex. jone@example.com"
                            variant="outlined"
                            error={!!emailError}
                            helperText={emailError || ''}
                            sx={{
                                width: '100%',
                                height: '46px',
                                borderRadius: '5px',
                                mb: '20px',
                                backgroundColor: Color.bgBlue,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white', 
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: '15px', color: Color.white }}><b>Contact Number</b></label>
                        {/* <TextField
                            onChange={(e) => setContact(e.target.value)}
                            size="small"
                            fullWidth

                            placeholder="Ex. 077xxxxxxx"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '46px',
                                borderRadius: '5px',
                                mb: '20px',
                                backgroundColor: Color.bgBlue,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: 'white', 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white', 
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white', 
                                },
                            }}
                        /> */}

                        <PhoneInput
                        country={'us'}
                        value={contact}
                        onChange={handlePhoneChange}
                        inputStyle={{
                            width: '100%',
                            height: '40px',
                            // borderRadius: '5px',
                            paddingLeft: '48px', 
                            backgroundColor:Color.bgBlue,
                            color:Color.white,
                            // border: 'double 1px white',
                        }}
                        buttonStyle={{
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #ccc',
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <label style={{ textAlign: 'left', fontSize: '15px', color: Color.white }}><b>Subject</b></label>
                        <TextField
                            onChange={(e) => setSubject(e.target.value)}
                            size="small"
                            fullWidth
                            placeholder="Subject"
                            variant="outlined"
                            sx={{
                                width: '100%',
                                height: '46px',
                                borderRadius: '5px',
                                mb: '20px',
                                backgroundColor: Color.bgBlue,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white', 
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label style={{ textAlign: 'left', fontSize: '15px', color: Color.white }}><b>Message</b></label>
                        <TextField
                            onChange={(e) => setRequirement(e.target.value)}
                            fullWidth
                            placeholder="What can we help you with?"
                            variant="outlined"
                            multiline
                            rows={4}
                            sx={{
                                width: '100%',
                                
                                borderRadius: '5px',
                                mb: '20px',
                                backgroundColor: Color.bgBlue,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: Color.bgBlue, 
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'white', 
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white', 
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SendButton onClick={handleMailSend}
                        >Send Now</SendButton>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ContactForm;
